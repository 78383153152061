import {PurchaseEvent, Transport} from "./Transport"

export interface TrackerInterface {
    trackEvent: (type: string, key: string, value?: TrackEventValueType) => void,
}

export type TrackEventValueType = string | {[key: string]: string | number} | null | {}

export class Tracker implements TrackerInterface {

    protected timings = new Map<string, Date>()

    constructor(
        private transport: Transport
    ) {}

    public trackEvent = (type: string, key: string, value: TrackEventValueType = null, channels?: string[]) => {
        const trackValue = value instanceof Object
            ? JSON.stringify(value)
            : value

        this.transport.trackEvent(type, key, trackValue, channels)
    }

    public trackScreen = (name: string) => {
        this.transport.trackScreen(name)
    }

    public trackTiming = (type: string, name: string) => {
        this.timings.set(type + name, new Date())
    }

    public trackTimingEnd = (type: string, name: string) => {
        const oldDate: Date = this.timings.get(type + name)
        if (oldDate) {
            const diff = new Date().getTime() - oldDate.getTime()

            this.transport.trackTiming(type, name, diff)
            this.timings.delete(type + name)
        }
    }

    /**
     * Яндекс метрика
     * @param id идентификатор цели
     */
    public yaTrack = (id: string) => {
        if (window.yaCounter20277427) {
            window.yaCounter20277427.reachGoal(id)
        }
    }

    /**
     * googleAnalitics
     * @param id идентификатор цели
     */
    public gaTrack = (id: string) => {
        if (typeof window.ga !== "undefined") {
            window.ga("send", "event", id)
        }
    }

    public trackPurchase = (event: PurchaseEvent) => {
        this.transport.trackPurchase(event)
    }
}
