import * as React from "react"
import {observer} from "mobx-react"
import classNames from "classnames/bind"
import {autobind} from "core-decorators"
import {CButton, CCustomScrollbars, CFlex, CHint, CIcon, CInput, Component} from "src/lib/components"
import {inject} from "src/lib/utils/inject"
import {FormattedMessageHtml, Intl} from "src/lib/utils/intl"
import {action, computed, observable, runInAction} from "mobx";
import * as Api from "src/lib/entities/api"
import {firstLetterToUpperCase} from "src/lib/utils/text";
import {CButtonType} from "src/lib/components/CButton/CButton";
import * as Collections from "src/lib/collections"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore";
import {
    COMPLETED_SYSTEM_SETTING_ID,
    Industries,
    MP_CLIENT_STATISTIC_SEND_INTERVIEW_URL,
    ROBOT_SETTING_ID,
    RobotSetting,
    TASKS_PROJECTS_ROBOT_ANSWER_ID,
    TRACKER_KEY,
    prepareDataForPollTracking,
    prepareDataForCompletePollTracking,
    ONBOARDING_POLL_EVENT_TYPE,
} from "../utils";
import fetch from "src/lib/utils/fetch"
import {FeatureStore} from "src/bums/common/stores/FeatureStore"
import {CSSTransitionGroup} from "react-transition-group"
import {Tracker} from "src/bums/common/stores/Tracker"
import {AccountInfoStore} from "src/lib/entities/store/AccountInfoStore"
import {BlockedPopupContentStore} from "src/bums/common/stores/BlockedPopupContentStore"
import {track} from "src/lib/tracking"
import {OnboardingPollStore, StepId} from "src/bums/common/onboarding/firstConfigure/store/OnboardingPollStore";
import {CStep} from "src/bums/common/onboarding/firstConfigure/components/CStep";
import {CStepOptions} from "src/bums/common/onboarding/firstConfigure/components/CStepOptions";
import {CRegistrationWithEmail} from "src/bums/common/onboarding/registrationWithEmail/CRegistrationWithEmail"

declare const window: Window & {
    carrotquest?: {
        identify: ({}) => void,
        track: (eventName: string, params?: number | string | boolean | string[]) => void,
    }
}
const style = classNames.bind(require("./styles/CFirstConfigureGlobalContainer.styl"))
const messages = require("./messages.yml")

@track({eventType: ONBOARDING_POLL_EVENT_TYPE})
@observer
@autobind
export class CFirstConfigureGlobalContainer extends Component {

    @inject(Intl)
    private intl: Intl

    @inject(Api.Store)
    private apiStore: Api.Store

    @inject(FeatureStore)
    private featureStore: FeatureStore

    @inject(Api.UserStore)
    private currentUser: Api.UserStore

    @inject(Tracker)
    private tracker: Tracker

    @inject(AccountInfoStore)
    private accountInfoStore: AccountInfoStore

    @inject(BlockedPopupContentStore)
    private blockedPopupContentStore: BlockedPopupContentStore

    @observable
    private isHidden = false

    @observable
    private animationDirection: "left" | "right" = "right"

    @inject(OnboardingPollStore)
    private store: OnboardingPollStore

    private robotSettingStore: UserSettingStore<RobotSetting>

    @observable
    private customIndustry: string

    @observable
    private isCompletedSettingLoaded = false

    private stepRenderMap = new Map<StepId, () => JSX.Element>([

        [StepId.Greeting, () => {
            const greeting = this.currentUser.user.firstName
                ? `${this.intl.formatMessage(messages.hello)}, ${firstLetterToUpperCase(this.currentUser.user.firstName)}!`
                : this.intl.formatMessage(messages.hello)

            return <CStep
                imageComponent={<div className={style("womanOkImage")} />}
                title={greeting}
                description={<FormattedMessageHtml {...messages["registrationSuccessfulComplete"]} />}
            />
        }],

        [StepId.ForWhom, () => <CStep
            imageComponent={<div className={style("manWithCoffeeImage")} />}
            title={this.intl.formatMessage(messages.howDoYouPlanToUseMegaplan)}
            description={this.intl.formatMessage(messages.chooseOptionForYourTasks)}

        >
            <CStepOptions
                options={
                    Collections.List([
                        {key: "forMyself", value: this.intl.formatMessage(messages.forMyself)},
                        {key: "forCompany", value: this.intl.formatMessage(messages.forCompany)},
                        {key: "forDepartment", value: this.intl.formatMessage(messages.forDepartment)},
                    ])
                }
                form={this.store.form}
                formField="forWhom"
            />
        </CStep>],

        [StepId.Industry, () => <CStep
            title={this.intl.formatMessage(messages.whatIndustry)}
            description={this.intl.formatMessage(messages.chooseIndustry)}

        >
            <CStepOptions
                options={this.forChooseIndustryOptions}
                form={this.store.form}
                formField="chooseIndustry"
                boxView
            />
        </CStep>],

        [StepId.YourRole, () => <CStep
            imageComponent={<div className={style("threeManImage")} />}
            title={this.intl.formatMessage(messages.chooseYourRole)}
            description={this.intl.formatMessage(messages.roleDescription)}

        >
            <CStepOptions
                options={this.forChooseRoleOptions}
                form={this.store.form}
                formField="chooseRole"
            />
        </CStep>],

        [StepId.HowManyEmployees, () => <CStep
            imageComponent={<div className={style("EmployeesImage")} />}
            title={this.store.stepTitle || this.intl.formatMessage(messages.howManyEmployees)}
            description={this.intl.formatMessage(messages.youWillBeAbleToConnectEmployeesAfterCompleting)}

        >
            <CStepOptions
                options={this.forHowManyEmployeesOptions}
                form={this.store.form}
                formField="howManyEmployees"
            />
        </CStep>],

        [StepId.NameOfYourCompany, () => <CStep
            imageComponent={<div className={style("manWithCoffeeImage")} />}
            title={this.intl.formatMessage(messages.whatIsTheNameOfYourCompany)}
            description={this.intl.formatMessage(messages.letUsGetToKnowYouBetter)}
        >
            {
                this.store.form.renderValue("nameOfYourCompany", bind => {
                    return <CFlex>
                        <CInput
                            {...bind}
                            size="large"
                            name="nameOfYourCompany"
                        />
                        <CHint
                            element={
                                <CIcon
                                    size={CIcon.Size.MEDIUM}
                                    type={CIcon.Type.INQUIRY}
                                />
                            }
                            direction="horizontal"
                            horizontalOrientation="left"
                        >
                            {this.intl.formatMessage(messages.theNameWillAppearInYourCompanyHint)}
                        </CHint>
                    </CFlex>
                })
            }
        </CStep>],

        [StepId.ExperienceWithCRM, () => <CStep
            imageComponent={<div className={style("EmployeeImage")} />}
            title={this.intl.formatMessage(messages.doYouHaveAnyExperienceWithCRMSystems)}
            description={this.intl.formatMessage(messages.orOtherCloudBasedBusinessManagementSystems)}
        >
            <CStepOptions
                options={
                    Collections.List([
                        {key: "yesIUseItNow", value: this.intl.formatMessage(messages.yesIUseItNow)},
                        {key: "yesImplementedEarlier", value: this.intl.formatMessage(messages.yesImplementedEarlier)},
                        {key: "introductoryOnly", value: this.intl.formatMessage(messages.introductoryOnly)},
                        {key: "no", value: this.intl.formatMessage(messages.no)},
                    ])
                }
                form={this.store.form}
                formField="experienceWithCRM"
            />
        </CStep>],

        [StepId.NameAndPhone, () => <CStep
            imageComponent={<div className={style("DesktopImage")} />}
            title={this.intl.formatMessage(messages.hello)}
            description={<FormattedMessageHtml {...messages["registrationSuccessfulComplete"]} />}
        >
            <CRegistrationWithEmail changeStepHandler={this.nextStep}/>
        </CStep>],

        [StepId.NamePhoneAndCompany, () => <CStep
            imageComponent={<div className={style("DesktopImage")} />}
            title={this.intl.formatMessage(messages.lastStep)}
            description={this.intl.formatMessage(messages.fillYouInfo)}
        >
            <CRegistrationWithEmail askCompanyName changeStepHandler={this.nextStep}/>
        </CStep>]
    ])

    public constructor(props: {}, context: any) {
        super(props, context)

        if (
            !this.featureStore.isAvailable("bums.common.onboarding_first_configure_show")
            || !this.currentUser.isAdmin
            || (
                localStorage
                && localStorage.getItem(COMPLETED_SYSTEM_SETTING_ID)
            )
        ) {
            this.hide()
            return
        }

        this.robotSettingStore = new UserSettingStore<RobotSetting>(
            this.apiStore,
            () => ROBOT_SETTING_ID,
            () => ({module: null})
        )

        this.robotSettingStore.get().promise.then(result => {
            if (result.module) {
                this.hide()
            }
        })

        void this.apiStore.fetchFullEntity<Api.SystemSetting<boolean>>(
            {
                ...Api.SystemSetting.newObject,
                id: COMPLETED_SYSTEM_SETTING_ID,
            }
        ).then(result => {
            runInAction(() => this.isCompletedSettingLoaded = true)
            if (!!result.value) {
                this.hide()
            }
        })

    }

    @action
    private hide() {
        this.isHidden = true
        // Разблокируем появление всплывающих окон
        this.blockedPopupContentStore.unblockContent()
    }

    @action
    private complete() {
        this.tracker.trackTimingEnd("onboardingPoll", TRACKER_KEY)
        this.tracker.yaTrack("onboardingPollFinish")

        this.hide()
        this.apiStore.update(
            {
                ...Api.SystemSetting.newObject,
                id: COMPLETED_SYSTEM_SETTING_ID,
                value: true,
            },
        )
        if (localStorage) {
            localStorage.setItem(COMPLETED_SYSTEM_SETTING_ID, "completed")
        }
    }

    @action
    private changeStepHandler(stepBack: boolean = false) {
        // перед сменой шага заканчиваем отслеживание времени пребывания на вопросе
        this.tracker.trackTimingEnd(`onboardingPoll_step${this.store.step }`, TRACKER_KEY)

        this.animationDirection = stepBack ? "left" : "right"

        this.store.takeStep(stepBack)

        if (this.store.step >= 0 && this.store.step < this.store.stepsMap.size) {
            //начинаем отслеживать время пребывания на вопросе
            this.tracker.trackTiming(`onboardingPoll_step${this.store.step }`, TRACKER_KEY)
        }
    }

    @action
    private secondaryButtonHandler() {
        if (this.store.canCancel) {
            if (this.store.currentPollPoint.skipPollFactory?.()) {
                this.store.takeSpecificStep(this.store.currentPollPoint.skipPollFactory())
                return
            } else {
                this.trackChangeStep(this.store, true)
                this.tracker.trackEvent("skip", TRACKER_KEY)
                this.tracker.yaTrack("onboardingPollCancel")
                void this.completeConfigureAndSendInfo(true)
            }
        } else {
            this.trackChangeStep(this.store, true)
            this.tracker.trackEvent("previousStep", TRACKER_KEY)
        }
        this.changeStepHandler(true)
    }

    @action
    private nextStep() {
        if (this.store.isFirstStep) {
            this.tracker.yaTrack("onboardingPollStart")
        }

        this.tracker.trackEvent(this.store.isFirstStep ? "start" : "nextStep", TRACKER_KEY, this.store.step.toString())
        this.trackChangeStep(this.store)

        if (this.store.isLastStep) {
            this.tracker.trackEvent("complete", TRACKER_KEY)
            this.trackCompletePoll(this.store)
            void this.completeConfigureAndSendInfo()
        } else {
            this.changeStepHandler()
        }
    }

    private trackChangeStep(store: OnboardingPollStore, stepBack?: boolean) {
        const eventData = prepareDataForPollTracking(arguments)
        if (eventData && eventData.event) {
            this.tracker.trackEvent(ONBOARDING_POLL_EVENT_TYPE, eventData.event, eventData.params, eventData.channels)
        }
    }

    private trackCompletePoll(store: OnboardingPollStore) {
        const eventData = prepareDataForCompletePollTracking(arguments)
        if (eventData && eventData.event) {
            this.tracker.trackEvent(ONBOARDING_POLL_EVENT_TYPE, eventData.event, eventData.params, eventData.channels)
        }
    }

    private renderStep(stepId: StepId) {
        if (!this.stepRenderMap.has(stepId)) {
            return null
        }

        return <React.Fragment>
            {this.stepRenderMap.get(stepId)()}
            {!this.store.currentPollPoint.customButtons && this.renderButtons(!this.store.canStepNext)}
        </React.Fragment>
    }

    @action
    private changeCustomIndustry(value: string) {
        this.customIndustry = value
        this.store.form.set("chooseIndustry", value)
    }

    private renderNextStepButton(disabled = false) {
        const captionMessageKey = this.store.isLastStep
            ? "complete"
            : this.store.isFirstStep
                ? "configure"
                : "continue"

        return <CButton
            name={`toStep${this.store.step + 1}`}
            className={style("nextStepButton")}
            caption={this.intl.formatMessage(messages[captionMessageKey])}
            onClick={this.nextStep}
            type={CButtonType.SUCCESS}
            iconRight={
                <CIcon
                    type={CIcon.Type.ARROW_FORWARD}
                    size={CIcon.Size.SMALL}
                    color={CIcon.Color.LIGHT}
                    className={style("nextStepRightIcon")}
                />
            }
            disabled={disabled}
        />
    }

    private renderSecondaryButton() {
        if (this.store.isRequiredQuestion) {
            return null
        }
        const name = this.store.canCancel
            ? "skipConfigure"
            : "back"

        const captionMessage = this.store.canCancel
            ? messages.skip
            : messages.back

        return <CButton
            name={name}
            caption={this.intl.formatMessage(captionMessage)}
            className={style("secondaryButton")}
            type={CButtonType.PALE}
            onClick={this.secondaryButtonHandler}
            icon={this.store.isFirstStep || this.store.canCancel ? null :
                <CIcon
                    className={style("backIcon")}
                    type={CIcon.Type.ARROW_BACK}
                    size={CIcon.Size.SMALL}
                />
            }
        />
    }

    private renderButtons(nextStepDisabled = false) {
        return <CFlex style={{marginTop: "34px"}}>
            {this.renderSecondaryButton()}
            {this.renderNextStepButton(nextStepDisabled)}
        </CFlex>
    }

    @action
    private async completeConfigureAndSendInfo(pollWasCanceled = false) {
        this.complete()

        // коммент в МуМУ
        const megaplanMessage = this.intl.formatMessage(messages.mumuCommentMessage, {answer: this.store.answers})
        const commentMessage = pollWasCanceled
            ? `${this.intl.formatMessage(messages.mumuCanceledCommentMessage)}` + (!!this.store.answers ? ` ${megaplanMessage}` : "")
            : megaplanMessage

        // урл для отправки (пока как в мобиле, смотри RobotStore)
        const url = MP_CLIENT_STATISTIC_SEND_INTERVIEW_URL
            + `email=${this.currentUser.email.value}`
            + `&mobile_goals=${this.store.answers}`
            + `&comment=${commentMessage}`
            + `&activityType=${this.store.chooseIndustryAnswer}`
            + `&isCustomActivityType=${this.store.isCustomIndustryAnswer}`
            + `&nameOfYourCompany=${this.store.form.get("nameOfYourCompany")}`
            + `&isCustomNameOfYourCompany=${this.store.isCustomNameOfYourCompanyAnswer}`

        if (process.env.NODE_ENV === "development") {
            console.warn("DEBUG: fetch url:" + url)
        } else {
            void fetch(url)
        }

        if (window && window.carrotquest && window.carrotquest.identify) {
            window.carrotquest.identify({poll: commentMessage})
            window.carrotquest.track("onboarding_poll_complited")
        }

        this.store.savePollResults(pollWasCanceled, commentMessage)

        if (pollWasCanceled) {
            return
        }

        // если прошли опрос в вебе, то не будем его показывать в мобиле
        const robotSettingValue = {module: null} as RobotSetting
        robotSettingValue.module = TASKS_PROJECTS_ROBOT_ANSWER_ID
        void this.robotSettingStore.set(robotSettingValue)

        // обновляем название Нашей компании
        const nameOfYourCompany = this.store.form.get("nameOfYourCompany")
        if (nameOfYourCompany) {
            try {
                const response = await this.apiStore.fetch<Api.ContractorCompany>("/api/v3/contractor/initialCreatedOwnCompany")
                await this.apiStore.update({
                    ...Api.getLink(response.value.data),
                    name: nameOfYourCompany,
                })
            } catch (e) {
                console.warn(e)
            }
        }
        window.location.hash = this.accountInfoStore.accountTarifId
    }

    @computed
    private get forHowManyEmployeesOptions() {
        return Collections.List([
            {key: "upTo5People", value: this.intl.formatMessage(messages.upTo5People)},
            {key: "from6To15People", value: this.intl.formatMessage(messages.from6To15People)},
            {key: "moreThan15People", value: this.intl.formatMessage(messages.moreThan15People)},
        ])
    }

    @computed
    private get forChooseRoleOptions() {
        return Collections.List([
            {key: "director", value: this.intl.formatMessage(messages.director)},
            {key: "manager", value: this.intl.formatMessage(messages.manager)},
            {key: "employee", value: this.intl.formatMessage(messages.employee)}
        ])
    }

    @computed
    private get forChooseIndustryOptions() {
        const options = Collections.List()

        Object.keys(Industries).forEach(element => {
            options.push({
                key: element, value: this.intl.formatMessage(messages[element])
            })
        })

        options.push({
            key: this.customIndustry || this.intl.formatMessage(messages.notSpecifiedIndustry),
            value: <CInput
                name="other"
                placeholder={this.intl.formatMessage(messages.otherPlaceholder)}
                value={this.customIndustry}
                onChange={this.changeCustomIndustry}
                autofocus={this.store.form.get("chooseIndustry") === this.customIndustry}
                status={this.store.form.get("chooseIndustry") === this.customIndustry ? "focus" : "normal"}
            />
        })

        return options
    }

    private renderStepSlider() {
        const circles: JSX.Element[] = []

        this.store.stepsMap.forEach((pollPoint, step) => {
            circles.push(
                <div className={style("circle", {selected: step === this.store.step })} key={`circleStep${step}`} />
            )
        })

        return <div>
            {circles}
        </div>
    }

    public render() {
        if (
            this.isHidden
            || this.robotSettingStore.get().state !== "fulfilled"
            || !this.isCompletedSettingLoaded
        ) {
            return null
        }

        // Заблокируем появление всплывающих окон
        this.blockedPopupContentStore.blockContent()

        if (StepId.Greeting === this.store.step ) {
            // трекаем реальное появление опроса на экране
            this.tracker.trackScreen(TRACKER_KEY)
            // трекаем начало прохождения опроса
            this.tracker.trackTiming("onboardingPoll", TRACKER_KEY)
            // начинаем отслеживать время пребывания на первом вопросе
            this.tracker.trackTiming(`onboardingPoll_step${this.store.step }`, TRACKER_KEY)
        }

        return <div className={style("global")}>
            <CCustomScrollbars>
                <div className={style("logoImage")} />
                <div className={style("slideWrapper")}>
                    <CSSTransitionGroup
                        transitionName={this.animationDirection}
                        transitionEnter={true}
                        transitionLeave={true}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={500}
                    >
                        <div className={style("slide")} key={this.store.step }>
                            {this.renderStep(this.store.step)}
                        </div>
                    </CSSTransitionGroup>
                </div>
                <div className={style("footer")}>
                    {this.renderStepSlider()}
                </div>
            </CCustomScrollbars>
        </div>
    }
}
