import {action, computed, observable} from "mobx"
import {autobind} from "core-decorators"
import * as Collections from "src/lib/collections"
import * as Api from "src/lib/entities/api"

import {createFormError, FormValidator, isEmpty} from "src/lib/utils/form/validation"
import {Intl} from "src/lib/utils/intl"
import {CompanyExtraFieldsStore} from "src/bums/common/stores/CompanyExtraFieldsStore"
import {ContractorCompanyTypesStore} from "src/bums/crm/stores/ContractorCompanyTypesStore"
import {
    ContactInfoFormCollection,
    getContactInfoType,
    isAddressField,
    isContactInfoField
} from "src/bums/common/form/form/ContactInfoForm"
import {AbstractModalForm} from "src/bums/common/modalForm/AbstractModalForm"
import {FileForm, FileStore} from "src/lib/entities/store/FileStore"
import {Form} from "src/lib/utils/form/form"
import {isContractorField} from "src/lib/components/CFieldValue/CRefValue/utils"
import {AbstractModalFormCollection} from "src/bums/common/modalForm/AbstractModalFormCollection"
import {EmployeeFormFactory} from "src/bums/common/form/EmployeeFormFactory"
import {
    ContractorFormFactoryImpl,
    ContractorHumanFormImpl,
    ContractorCompanyFormImpl,
    ContractorCompanyFormFactoryImpl
} from "./types"
import {StaticContractorFormFields} from "src/bums/crm/utils"
import {isknownFieldMetaData, KnownMetadata} from "src/lib/components/CFieldValue/utils"
import {getFieldSettingValue} from "src/lib/entities/api"
import {UserSettingStore} from "src/bums/common/stores/UserSettingStore"
import {prepareExtraFieldDefaultValue} from "src/lib/utils/form/utils";

const libMessages: any = require("src/lib/messages.yml")
const messages: any = require("../messages.yml")


function companyNameValidator(intl: Intl) {
    return (name: string, contractorCompany: Api.ContractorCompany) => {
        if (isEmpty(contractorCompany.name)) {
            return createFormError(intl.formatMessage(libMessages["emptyFieldError"]))
        }
    }
}

@autobind
export class ContractorCompanyFormFactory implements ContractorCompanyFormFactoryImpl {

    public addressTypes: Api.ListStore<Api.AddressType>

    constructor(
        private $intl: Intl,
        private $apiStore: Api.Store,
        private $userStore: Api.UserStore,
        private $companyExtraFieldsStore: CompanyExtraFieldsStore,
        private $contractorCompanyTypesStore: ContractorCompanyTypesStore,
        private contractorFormFactory: ContractorFormFactoryImpl,
        private employeeFormFactory: EmployeeFormFactory,
    ) {
        this.addressTypes = this.$apiStore.resolveList<Api.AddressType>(
            () => ({ endpoint: `/api/v3/contractor/addressTypes` })
        )
    }

    @computed
    private get contactInfoFields() {
        return this.$companyExtraFieldsStore
            .listStore
            .originalItems
            .filter(field => isContactInfoField(field.name) || isAddressField(field.name))
    }

    @computed
    private get contactInfoEntitiesByTypeMap() {
        return this.contactInfoFields
            .reduce((result, field) => {
                if (isAddressField(field.name)) {
                    return result.set(field.name, {
                        ...Api.Address.newObject,
                        type: this.addressTypes.originalItems.first()
                    })
                }
                if (isContactInfoField(field.name)) {
                    const type = getContactInfoType(field.name)
                    return result.set(type, {...Api.ContactInfo.newObject, type})
                }
                return result
            }, new Map<string, Api.ContactInfo | Api.Address>())
    }

    public get contactInfoTypes() {
        return Array.from(this.contactInfoEntitiesByTypeMap.keys())
    }

    @computed
    private get requiredContactInfoTypes() {
        return this.contactInfoFields
            .filter(field => Api.getFieldSettingValue(field, "card_required", false))
            .map(field => {
                return (isContactInfoField(field.name) ? getContactInfoType(field.name) : field.name) as string
            })
    }

    /**
     * Поля с дефолтными значениями
     * (берутся только для полей с типом Bool и Enum: только их значения пользователь может указывать явно
     */
    private get visibleExtraFields() {
        return this.$companyExtraFieldsStore
            .getFieldsWithSetting("add_form_visibility")
            .filterNot(f => f.name === "gender" || f.name === "textDescription" || isContactInfoField(f.name) || isAddressField(f.name))
            .reduce((result, field) => {
                if (!field) {
                    return result
                }

                result[field.name] = prepareExtraFieldDefaultValue(field)
                return result
            }, {} as { [fieldName: string]: any })
    }

    /**
     * Какой филд как валидировать и какую ошибку возвращать
     */
    private get formValidator() {
        const validationMap = new FormValidator<Api.ContractorCompany>()
        validationMap.set(Api.ContractorCompany.fields.name.name, companyNameValidator(this.$intl))
        return this.$companyExtraFieldsStore
            .getFieldsWithSetting("card_required")
            .filterNot(f => f.name === "gender" || f.name === "textDescription" || isContactInfoField(f.name) || isAddressField(f.name))
            .reduce((result, field) => {
                if (Api.isFloatField(field)) {
                    const {minValue, maxValue} = field
                    result.set(field.name, FormValidator.floatFieldValidator(minValue, maxValue, {
                        emptyError: this.$intl.formatMessage(libMessages["emptyFieldError"]),
                        rangeError: this.$intl.formatMessage(libMessages["constraintNumberPlaceholder"], {maxValue, minValue})
                    }))
                } else {
                    result.set(field.name, FormValidator.nonEmptyFieldValidator(
                        this.$intl.formatMessage(libMessages["emptyFieldError"])
                    ))
                }
                return result
            }, validationMap)
    }

    public getDependencies() {
        return [
            this.addressTypes.whenComplete(),
            this.$companyExtraFieldsStore.listStore.whenComplete(),
            this.$contractorCompanyTypesStore.listStore.whenComplete()
        ]
    }

    public async create(
        value: Api.ContractorCompany,
        fixedFields: (keyof Api.ContractorCompany)[] = [],
        customSubmitHandler?: (entity: Api.Contractor) => void
    ) {
        await Promise.all(this.getDependencies())
        return this.createForm(value, fixedFields, customSubmitHandler)
    }

    public createForm(
        value: Api.ContractorCompany,
        fixedFields: (keyof Api.ContractorCompany)[] = [],
        customSubmitHandler?: (entity: Api.Contractor) => void
    ) {
        const formValue = {
            ...this.visibleExtraFields,
            name: "",
            description: "",
            type: this.$contractorCompanyTypesStore.listStore.originalItems.first(),
            attaches: Collections.List<Api.File>(),
            responsibles: Collections.List<Api.User | Api.Group>([this.$userStore.user]),
            contactInfo: Collections.List(this.contactInfoEntitiesByTypeMap.values()),
            ...value,
            contentType: Api.ContractorCompany.contentType
        }

        const form =  new ContractorCompanyForm(
            () => formValue,
            this.$apiStore,
            this.formValidator,
            this.$intl,
            this.requiredContactInfoTypes.toArray(),
            Array.from(this.contactInfoEntitiesByTypeMap.keys()),
            this.$companyExtraFieldsStore,
            this.contractorFormFactory,
            this.employeeFormFactory,
        )
        form.setFixedFields(fixedFields)
        if (customSubmitHandler) {
            form.setCustomSubmitHandler(customSubmitHandler)
        }
        return form
    }
}

@autobind
export class ContractorCompanyForm extends AbstractModalForm<Api.ContractorCompany> implements ContractorCompanyFormImpl {

    private fileStore: FileStore

    @observable
    public attaches: FileForm

    @observable
    protected fieldsSettingStore: UserSettingStore<string[]>

    constructor(
        valueFactory: () => Api.ContractorCompany,
        apiStore: Api.Store,
        validationMap: FormValidator<Api.ContractorCompany>,
        private intl: Intl,
        private $requiredContactInfoTypes: string[],
        public allContactInfoTypes: string[],
        public extraFieldsStore: CompanyExtraFieldsStore,
        private contractorFormFactory: ContractorFormFactoryImpl,
        private employeeFormFactory: EmployeeFormFactory,
    ) {
        super(valueFactory, apiStore, validationMap)
        this.fileStore = new FileStore(this.apiStore)
        this.attaches = this.form("attaches", (files) =>  new FileForm(() => files, this.fileStore, this.intl))

        this.extraFieldsStore.listStore.originalItems.forEach(field => {

            if (isContractorField(field)) {
                (this as any)[field.name] = this.form(field.name, contractor => {

                    if (!field.isMultiple) {
                        return this.contractorFormFactory.createFormForContactor(contractor)
                    } else {
                        return new AbstractModalFormCollection<
                            Api.Contractor,
                            ContractorHumanFormImpl | ContractorCompanyFormImpl | Form<Api.Contractor>
                        >(
                            () => contractor,
                            item => this.contractorFormFactory.createFormForContactor(item)
                        )
                    }
                })
            }
        })

        this.fieldsSettingStore = new UserSettingStore<string[]>(
            apiStore,
            () => `contractor_company_${this.get("type") ? this.get("type").type : ""}_card_fields`,
            () => []
        )

    }

    @action
    onContractorTypeChange(newValue: Api.ContractorType) {
        this.set("type", newValue)
    }

    @observable
    public contactInfo = this.form(
        "contactInfo",
        contacts => new ContactInfoFormCollection(() => contacts, this.$requiredContactInfoTypes, this.intl)
    )

    @observable
    public responsibles = this.form(
        Api.ContractorCompany.fields.responsibles.name, (fieldValue) => this.employeeFormFactory.create(fieldValue)
    )

    public getValueForSave(value: Partial<Api.ContractorCompany>) {

        value = super.getValueForSave(value)

        if (value.contactInfo) {
            return {
                ...value,
                contactInfo: value.contactInfo.filter(c => c.value && !isEmpty(c.value))
            }
        }

        return value
    }

    private get staticCardFields() {
        return StaticContractorFormFields(this.intl)
    }

    @computed
    public get extraFields(): KnownMetadata[] {
        return this.extraFieldsStore.listStore.originalItems
        // Оставляем известные экстраполя которые отмечены как видимые для карточки создания
            .filter(field =>
                !!field.name
                || (isknownFieldMetaData(field) && getFieldSettingValue(field, "add_form_visibility", false))
            )
            .filter(field => ![
                Api.ContractorCompany.fields.textDescription.name,
                Api.ContractorCompany.fields.type.name,
                Api.ContractorCompany.fields.position.name,
                Api.ContractorHuman.fields.gender.name,
            ].includes(field.name))
            .sort((field1, field2) => {
                const field1orderPos = field1.orderPos === void 0 ? -Infinity : field1.orderPos
                const field2orderPos = field2.orderPos === void 0 ? -Infinity : field2.orderPos
                return field1orderPos > field2orderPos ? 1
                    : field1orderPos < field2orderPos ? -1
                        : 0
            })
            .toArray() as KnownMetadata[]
    }

    @computed
    public get allFields() {
        return this.staticCardFields.concat(this.extraFields.map(field => {
            let hrName = field.hrName
            if (!hrName) {
                if (field.name === "responsibles") {
                    hrName = this.intl.formatMessage(messages[field.name], {num: 1})
                } else if (field.name === "gender") {
                    hrName = this.intl.formatMessage(libMessages[field.name])
                } else if (isContactInfoField(field.name)) {
                    const contactField = this.allContactInfoTypes.find(item => field.name.includes(item))
                    hrName = this.intl.formatMessage(messages[contactField])
                } else {
                    hrName = this.intl.formatMessage(messages[field.name])
                }
            }
            return {
                name: field.name,
                hrName: hrName,
                isNotConfigurable: field.isRequired || this.isRequired(field.name)
            }
        }))
    }

    @computed
    public get selectedFieldsNames(): string[] {
        const setting = this.fieldsSettingStore.get()

        if (setting.state === "fulfilled") {
            let fieldNames = setting.value

            // Если сохранена пустая настройка то выводим все поля, таким образом на всех существующих аккаунтах
            // будут отображаться все поля, а на новых только дефолтные поля
            if (!fieldNames.length) {
                return this.staticCardFields
                    .map(field => field.name)
                    .concat(this.extraFields.filter(field => getFieldSettingValue(field, "add_form_visibility", false))
                        .map(field => field.name))
            }

            // обязательные филды должны быть отображены даже если они не выбраны
            const requiredExtraFieldsNames = this.extraFields
                .filter(field => (field.isRequired || this.isRequired(field.name)) && !fieldNames.includes(field.name))
                .map(field => field.name)

            // убедимся, что в сохраненной настройке будут только поля, доступные в клиенте
            const allFieldNames = this.allFields.map(field => field.name)

            return fieldNames.concat(requiredExtraFieldsNames).filter(value => allFieldNames.includes(value))
        }

        return []
    }

    public async setSelectedFieldsNames(value: string[]) {
        await this.fieldsSettingStore.set(value)
    }

}

export function isContractorCompanyForm(form: any): form is ContractorCompanyForm {
    return !!form && form instanceof ContractorCompanyForm
}
