import {autobind} from "core-decorators"
import * as Api from "src/lib/entities/api"

const CHECK_INTERVAL_TIME = 1 * 60 * 1000

@autobind
export class AuthorizationCheckerStore {

    protected $interval: number

    constructor(
        protected $apiStore: Api.Store,
        protected $userStore: Api.UserStore
    ) {}

    public attachChecker() {
        this.$interval = setInterval(async () => {
            try {
                await this.$apiStore.fetchEntities<Api.User>(
                    `/api/v3/employee/${this.$userStore.user.id}?{"onlyRequestedFields":true,"fields":["id"]}`
                )
            } catch (err) {
                if (err.response.status === 403) {
                    window.location.reload()
                }
            }
        }, CHECK_INTERVAL_TIME)
    }

    public dettachChecker() {
        clearInterval(this.$interval)
    }
}
